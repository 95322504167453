
import { defineComponent, inject } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import useContractFromTemplate from "@/lib/compositional-logic/useContractFromTemplate";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TemplateDone",
  components: {
    FlatButton,
    TextButton,
  },
  props: {
    templateId: { type: String, default: "" },
    folderId: { type: String, default: "" },
    folderName: { type: String, default: "" },
    isDocxTemplate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const router = useRouter();

    const { createContractFromTemplate } = useContractFromTemplate();

    const returnTemplateList = () => {
      if (props.folderId == "") {
        router.push(`/template`);
      } else {
        router.push(`/template?folderId=${props.folderId}`);
      }
    };

    return {
      isMobile,
      createContractFromTemplate,
      returnTemplateList,
    };
  },
});
